<template>
  <admin-fillall-layout>
    <div class="setting-content">
      <tab-content
        :tab-list="tabList"
      />
    </div>
  </admin-fillall-layout>
</template>

<script>
import TabContent from '@/components/TabContent.vue';
import AccountProfile from './AccountProfile.vue';
import About from './About.vue';

export default {
  name: 'AccountSetting',
  components: {
    AccountProfile,
    About,
    TabContent,
  },
  data() {
    return {
      activeName: null,
      tabList: [
        {
          label: 'header.profile',
          name: 'profile',
          component: AccountProfile,
        },
        {
          label: 'header.setPass',
          name: 'setPass',
          component: About,
        },
      ],
    };
  },
};
</script>

<style lang="scss">

.setting-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #f3f3f3;

  .setting-tab-content {
    flex-grow: 1;
    position: relative;
  }

  .sub-app {
    padding-top: 35px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .app-main-toolbar {
      flex-grow: 0;
      .el-row {
        h1 {
          margin-top: 0;
        }
      }
    }

    .app-main-content {
      flex-grow: 1;
      position: relative;
    }
  }
}
</style>
