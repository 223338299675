<template>
  <div class="sub-app location" id="account-profile">
    <!-- <section class="app-main-toolbar">
      <el-row class="no-margin">
        <h1>Locations</h1>
      </el-row>
      <el-row type="flex" justify="end">
        <el-button type="primary"
                   class="main-actions"
                   @click="dialogOpen()"
        ><fa-icon icon="plus"/> {{ $t('button.add') }}</el-button>
      </el-row>
    </section> -->
    <section class="app-main-content">
      <div class="profile">
        <div class="leftPadding">
          <el-row
            class="header-row"
            type="flex"
            align="center"
            justify="space-between"
          >
            <div>
              <img
                class="thumbnail"
                src="/asserts/icon/userprofile-icon.svg"
              >
            </div>
            <div class="header-row__name">
              <div class="valueLabel">
                {{ authUser.name }}
              </div>
              <hr>
            </div>
          </el-row>

          <!-- <el-row class="first-topPadding" type="flex" align="bottom" justify="space-between" >

          Charge          <el-switch v-model="drone.charge"></el-switch>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row> -->

          <el-row
            class="topPadding"
            type="flex"
            align="bottom"
            justify="space-between"
          >
            {{ $t('page.setting.userTab.dialog.account') }}        <div class="valueLabel1">
              {{ username }}
            </div>
          </el-row>
          <el-row style="padding-right:35%;">
            <hr>
          </el-row>
          <el-row
            class="topPadding tooltip"
            type="flex"
            align="bottom"
            justify="space-between"
          >
            {{ $t('page.setting.userTab.dialog.groups') }}<div class="valueLabel1">
              {{ format(groups) }}
              <span class="tooltip-text">{{ format(groups) }}</span>
            </div>
          </el-row>
          <el-row style="padding-right:35%;">
            <hr>
          </el-row>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { CopyObject } from '@/utils/common.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {

  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      username: 'user/username',
      authUser: 'user/user',
      groups: 'user/groups',
    }),
  },

  data() {
    return {
      account: CopyObject(this.$store.state.account.account),

    };
  },
  methods: {
    format(groups) {
      let name = '';
      this.groups.forEach((element) => {
        name = `${name + element.name}/`;
      });
      return name;
    },
    ...mapActions({

    }),
  },
  mounted() {

  },
};
</script>

<style lang="scss">
#account-profile {
  .profile{
    width: 100%;
    height: 100%;
    background-color:#f3f3f3;

    .leftPadding{
      position: relative;
      margin-left: 30%;
      top :72px;

      .header-row{
        height: 133px;
        padding-right:35%;
        .header-row__name {
          width: 60%;
          .valueLabel{
            height: 18px;
            width: 100%;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #787878;
            margin-top: 40px;
          }
        }
      }

      .first-topPadding{
        margin-top: 52px;
        padding-right:35%;
      }
      .topPadding{
        margin-top: 40px;
        padding-right:35%;
      }
    }
  }

  .valueLabel1{
    width: 361px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #787878;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 25px;
  }
}

</style>
<style lang="scss" scoped>

.tooltip {
  position: relative;
}

.tooltip .tooltip-text {
  visibility: hidden;
  max-width: 50vw;
  background-color: #e5e5e5;
  color: #979797;
  text-align: left;
  padding: 5px 8px;
  font-size: 12px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  word-break: break-all;
  white-space: pre-wrap;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 180%;
  left: 25%;
  margin-left: -60px;
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

</style>
