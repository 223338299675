<template>
  <div class="sub-app location">
    <section class="app-main-content">
      <div class="profile">
        <div class="leftPadding">
          <el-row
            class="topPadding"
            type="flex"
            align="bottom"
            justify="space-between"
          >
            <label
              class="la1"
              for=""
            >{{ $t('page.setting.userTab.dialog.oldPassword') }}</label>

            <div style="width:20px" />
            <el-input
              v-model="oldPassword"
              type="password"
            />
          </el-row>
          <el-row style="padding-right:35%;" />
          <el-row style="padding-right:35%;" />
          <el-row
            class="topPadding"
            type="flex"
            align="bottom"
            justify="space-between"
          >
            <label
              class="la1"
              for=""
            >{{ $t('page.setting.userTab.dialog.newPassword') }}</label>
            <div style="width:20px" />
            <el-input
              v-model="newPassword"
              type="password"
            />
          </el-row>
          <el-row style="padding-right:35%;" />
          <el-row style="padding-right:35%;" />
          <el-row
            class="topPadding"
            type="flex"
            align="bottom"
            justify="space-between"
          >
            <label
              class="la1"
              for=""
            >{{ $t('page.setting.userTab.dialog.confirmPassword') }}</label>
            <div style="width:20px" />
            <el-input
              v-model="confirmPassword"
              type="password"
            />
          </el-row>
          <el-row style="padding-right:35%;" />
          <el-row style="padding-right:35%;" />
          <div class="bottomCenter">
            <el-button
              class="main-actions"
              type="primary"
              @click="updatePassword"
            >
              {{ $t('button.save') }}
            </el-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userApi from '@/services/api/domain/user';

export default {
  name: 'AboutSubApp',
  data() {
    return {
      config: this.$config,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      username: 'user/username',
    }),
  },
  methods: {
    async updatePassword() {
      this.errors = [];

      if (!this.oldPassword) {
        this.errors.push('Old password required.');
      }
      if (!this.newPassword) {
        this.errors.push('New password required.');
      }
      if (!this.confirmPassword) {
        this.errors.push('Confirmed password required.');
      }
      if (!(this.newPassword === this.confirmPassword)) {
        this.errors.push('New password inconsistent.');
      }

      if (this.errors.length) {
        this.$showFail(this.errors[0]);
        return true;
      }

      const data = {
        account: this.username,
        password: this.confirmPassword,
        old_password: this.oldPassword,
      };
      try {
        const status = await userApi.changePass(data);
        this.$showSuccess(status.msg);

        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
      } catch (error) {
        this.$showFail(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leftPadding{
  width: 57%;
  max-width: 496px;
  margin: 0 auto;
  padding-top: 33px
}

.el-row--flex.is-align-bottom {
  align-items: center;
  padding: 18px 0px;
}

.el-button.main-actions {
  padding: 9px 28px
}

.bottomCenter{
  position: absolute;
  bottom: 8%;
  left: 47%;
}

.la1{
  width: 200px;
}
</style>
